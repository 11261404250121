@import "https://fonts.googleapis.com/css?family=Open+Sans:300,300italic,600,600italic,700";
html, body, div, span, applet, object, iframe, h1, h2, h3, h4, h5, h6, p, blockquote, pre, a, abbr, acronym, address, big, cite, code, del, dfn, em, img, ins, kbd, q, s, samp, small, strike, strong, sub, sup, tt, var, b, u, i, center, dl, dt, dd, ol, ul, li, fieldset, form, label, legend, table, caption, tbody, tfoot, thead, tr, th, td, article, aside, canvas, details, embed, figure, figcaption, footer, header, hgroup, menu, nav, output, ruby, section, summary, time, mark, audio, video {
  font-size: 100%;
  font: inherit;
  vertical-align: baseline;
  border: 0;
  margin: 0;
  padding: 0;
}

article, aside, details, figcaption, figure, footer, header, hgroup, menu, nav, section {
  display: block;
}

body {
  line-height: 1;
}

ol, ul {
  list-style: none;
}

blockquote, q {
  quotes: none;
}

blockquote:before, blockquote:after, q:before, q:after {
  content: "";
  content: none;
}

table {
  border-collapse: collapse;
  border-spacing: 0;
}

body {
  -webkit-text-size-adjust: none;
}

mark {
  color: inherit;
  background-color: #0000;
}

input::-moz-focus-inner {
  border: 0;
  padding: 0;
}

input, select, textarea {
  appearance: none;
}

html {
  box-sizing: border-box;
}

*, :before, :after {
  box-sizing: inherit;
}

body {
  padding-top: 3.5em;
}

body.is-preload *, body.is-preload :before, body.is-preload :after {
  -ms-animation: none !important;
  transition: none !important;
  animation: none !important;
}

body, input, textarea, select {
  color: #888;
  font-family: Open Sans, sans-serif;
  font-size: 13pt;
  font-weight: 300;
  line-height: 1.85em;
}

a {
  color: #43b3e0;
  text-decoration: underline;
  transition: color .2s ease-in-out;
}

a:hover {
  color: #43bff0 !important;
}

a img {
  border: 0;
}

b, strong {
  color: #3e3e3e;
  font-weight: 600;
}

i, em {
  font-style: italic;
}

sub {
  font-size: .8em;
  position: relative;
  top: .5em;
}

sup {
  font-size: .8em;
  position: relative;
  top: -.5em;
}

blockquote {
  border-left: .75em solid #eee;
  padding: 1em 0 1em 1.5em;
  font-style: italic;
}

h1, h2, h3, h4, h5, h6 {
  color: #3e3e3e;
  margin: 0 0 .75em;
}

h1 a, h2 a, h3 a, h4 a, h5 a, h6 a {
  color: inherit;
  text-decoration: none;
}

h2, h3, h4, h5, h6 {
  font-weight: 700;
}

h1 {
  letter-spacing: -.025em;
  font-size: 3.25em;
  font-weight: 300;
}

h1 strong {
  font-weight: 700;
}

h2 {
  letter-spacing: -.015em;
  font-size: 2em;
}

h3 {
  letter-spacing: -.015em;
  font-size: 1.5em;
}

em, i {
  font-style: italic;
}

br.clear {
  clear: both;
}

hr {
  border: 0;
  border-top: 1px solid #00000059;
  height: 1px;
  margin: 3em 0;
  box-shadow: 0 1px #ffffff1a;
}

p, ul, ol, dl, table {
  margin-bottom: 2em;
}

header {
  margin: 0 0 3em;
}

header > p, header > div > p {
  margin: 0;
  font-size: 1.25em;
}

footer {
  margin: 3em 0 0;
}

footer > p {
  font-size: 1.25em;
}

.container {
  width: 1600px;
  max-width: calc(100% - 50px);
  margin: 0 auto;
}

.container.medium {
  width: 900px;
}

@media screen and (width <= 1680px) {
  .container {
    width: 1200px;
  }

  .container.medium {
    width: 900px;
  }
}

@media screen and (width <= 1280px) {
  .container {
    width: 960px;
  }

  .container.medium {
    width: 720px;
  }
}

@media screen and (width <= 980px) {
  .container {
    width: 100%;
  }

  .container.medium {
    width: 75%;
  }
}

@media screen and (width <= 736px) {
  .container {
    width: 100%;
    max-width: calc(100% - 30px);
  }

  .container.medium {
    width: 100%;
  }
}

.row {
  box-sizing: border-box;
  flex-wrap: wrap;
  align-items: stretch;
  display: flex;
}

.row > * {
  box-sizing: border-box;
}

.row.gtr-uniform > * > :last-child {
  margin-bottom: 0;
}

.row.aln-left {
  justify-content: flex-start;
}

.row.aln-center {
  justify-content: center;
}

.row.aln-right {
  justify-content: flex-end;
}

.row.aln-top {
  align-items: flex-start;
}

.row.aln-middle {
  align-items: center;
}

.row.aln-bottom {
  align-items: flex-end;
}

.row > .imp {
  order: -1;
}

.row > .col-1 {
  width: 8.33333%;
}

.row > .off-1 {
  margin-left: 8.33333%;
}

.row > .col-2 {
  width: 16.6667%;
}

.row > .off-2 {
  margin-left: 16.6667%;
}

.row > .col-3 {
  width: 25%;
}

.row > .off-3 {
  margin-left: 25%;
}

.row > .col-4 {
  width: 33.3333%;
}

.row > .off-4 {
  margin-left: 33.3333%;
}

.row > .col-5 {
  width: 41.6667%;
}

.row > .off-5 {
  margin-left: 41.6667%;
}

.row > .col-6 {
  width: 50%;
}

.row > .off-6 {
  margin-left: 50%;
}

.row > .col-7 {
  width: 58.3333%;
}

.row > .off-7 {
  margin-left: 58.3333%;
}

.row > .col-8 {
  width: 66.6667%;
}

.row > .off-8 {
  margin-left: 66.6667%;
}

.row > .col-9 {
  width: 75%;
}

.row > .off-9 {
  margin-left: 75%;
}

.row > .col-10 {
  width: 83.3333%;
}

.row > .off-10 {
  margin-left: 83.3333%;
}

.row > .col-11 {
  width: 91.6667%;
}

.row > .off-11 {
  margin-left: 91.6667%;
}

.row > .col-12 {
  width: 100%;
}

.row > .off-12 {
  margin-left: 100%;
}

.row.gtr-0 {
  margin-top: 0;
  margin-left: 0;
}

.row.gtr-0 > * {
  padding: 0;
}

.row.gtr-0.gtr-uniform {
  margin-top: 0;
}

.row.gtr-0.gtr-uniform > * {
  padding-top: 0;
}

.row.gtr-25 {
  margin-top: -6.25px;
  margin-left: -6.25px;
}

.row.gtr-25 > * {
  padding: 6.25px 0 0 6.25px;
}

.row.gtr-25.gtr-uniform {
  margin-top: -6.25px;
}

.row.gtr-25.gtr-uniform > * {
  padding-top: 6.25px;
}

.row.gtr-50 {
  margin-top: -12.5px;
  margin-left: -12.5px;
}

.row.gtr-50 > * {
  padding: 12.5px 0 0 12.5px;
}

.row.gtr-50.gtr-uniform {
  margin-top: -12.5px;
}

.row.gtr-50.gtr-uniform > * {
  padding-top: 12.5px;
}

.row {
  margin-top: -25px;
  margin-left: -25px;
}

.row > * {
  padding: 25px 0 0 25px;
}

.row.gtr-uniform {
  margin-top: -25px;
}

.row.gtr-uniform > * {
  padding-top: 25px;
}

.row.gtr-150 {
  margin-top: -37.5px;
  margin-left: -37.5px;
}

.row.gtr-150 > * {
  padding: 37.5px 0 0 37.5px;
}

.row.gtr-150.gtr-uniform {
  margin-top: -37.5px;
}

.row.gtr-150.gtr-uniform > * {
  padding-top: 37.5px;
}

.row.gtr-200 {
  margin-top: -50px;
  margin-left: -50px;
}

.row.gtr-200 > * {
  padding: 50px 0 0 50px;
}

.row.gtr-200.gtr-uniform {
  margin-top: -50px;
}

.row.gtr-200.gtr-uniform > * {
  padding-top: 50px;
}

@media screen and (width <= 1680px) {
  .row {
    box-sizing: border-box;
    flex-wrap: wrap;
    align-items: stretch;
    display: flex;
  }

  .row > * {
    box-sizing: border-box;
  }

  .row.gtr-uniform > * > :last-child {
    margin-bottom: 0;
  }

  .row.aln-left {
    justify-content: flex-start;
  }

  .row.aln-center {
    justify-content: center;
  }

  .row.aln-right {
    justify-content: flex-end;
  }

  .row.aln-top {
    align-items: flex-start;
  }

  .row.aln-middle {
    align-items: center;
  }

  .row.aln-bottom {
    align-items: flex-end;
  }

  .row > .imp-xlarge {
    order: -1;
  }

  .row > .col-1-xlarge {
    width: 8.33333%;
  }

  .row > .off-1-xlarge {
    margin-left: 8.33333%;
  }

  .row > .col-2-xlarge {
    width: 16.6667%;
  }

  .row > .off-2-xlarge {
    margin-left: 16.6667%;
  }

  .row > .col-3-xlarge {
    width: 25%;
  }

  .row > .off-3-xlarge {
    margin-left: 25%;
  }

  .row > .col-4-xlarge {
    width: 33.3333%;
  }

  .row > .off-4-xlarge {
    margin-left: 33.3333%;
  }

  .row > .col-5-xlarge {
    width: 41.6667%;
  }

  .row > .off-5-xlarge {
    margin-left: 41.6667%;
  }

  .row > .col-6-xlarge {
    width: 50%;
  }

  .row > .off-6-xlarge {
    margin-left: 50%;
  }

  .row > .col-7-xlarge {
    width: 58.3333%;
  }

  .row > .off-7-xlarge {
    margin-left: 58.3333%;
  }

  .row > .col-8-xlarge {
    width: 66.6667%;
  }

  .row > .off-8-xlarge {
    margin-left: 66.6667%;
  }

  .row > .col-9-xlarge {
    width: 75%;
  }

  .row > .off-9-xlarge {
    margin-left: 75%;
  }

  .row > .col-10-xlarge {
    width: 83.3333%;
  }

  .row > .off-10-xlarge {
    margin-left: 83.3333%;
  }

  .row > .col-11-xlarge {
    width: 91.6667%;
  }

  .row > .off-11-xlarge {
    margin-left: 91.6667%;
  }

  .row > .col-12-xlarge {
    width: 100%;
  }

  .row > .off-12-xlarge {
    margin-left: 100%;
  }

  .row.gtr-0 {
    margin-top: 0;
    margin-left: 0;
  }

  .row.gtr-0 > * {
    padding: 0;
  }

  .row.gtr-0.gtr-uniform {
    margin-top: 0;
  }

  .row.gtr-0.gtr-uniform > * {
    padding-top: 0;
  }

  .row.gtr-25 {
    margin-top: -6.25px;
    margin-left: -6.25px;
  }

  .row.gtr-25 > * {
    padding: 6.25px 0 0 6.25px;
  }

  .row.gtr-25.gtr-uniform {
    margin-top: -6.25px;
  }

  .row.gtr-25.gtr-uniform > * {
    padding-top: 6.25px;
  }

  .row.gtr-50 {
    margin-top: -12.5px;
    margin-left: -12.5px;
  }

  .row.gtr-50 > * {
    padding: 12.5px 0 0 12.5px;
  }

  .row.gtr-50.gtr-uniform {
    margin-top: -12.5px;
  }

  .row.gtr-50.gtr-uniform > * {
    padding-top: 12.5px;
  }

  .row {
    margin-top: -25px;
    margin-left: -25px;
  }

  .row > * {
    padding: 25px 0 0 25px;
  }

  .row.gtr-uniform {
    margin-top: -25px;
  }

  .row.gtr-uniform > * {
    padding-top: 25px;
  }

  .row.gtr-150 {
    margin-top: -37.5px;
    margin-left: -37.5px;
  }

  .row.gtr-150 > * {
    padding: 37.5px 0 0 37.5px;
  }

  .row.gtr-150.gtr-uniform {
    margin-top: -37.5px;
  }

  .row.gtr-150.gtr-uniform > * {
    padding-top: 37.5px;
  }

  .row.gtr-200 {
    margin-top: -50px;
    margin-left: -50px;
  }

  .row.gtr-200 > * {
    padding: 50px 0 0 50px;
  }

  .row.gtr-200.gtr-uniform {
    margin-top: -50px;
  }

  .row.gtr-200.gtr-uniform > * {
    padding-top: 50px;
  }
}

@media screen and (width <= 1280px) {
  .row {
    box-sizing: border-box;
    flex-wrap: wrap;
    align-items: stretch;
    display: flex;
  }

  .row > * {
    box-sizing: border-box;
  }

  .row.gtr-uniform > * > :last-child {
    margin-bottom: 0;
  }

  .row.aln-left {
    justify-content: flex-start;
  }

  .row.aln-center {
    justify-content: center;
  }

  .row.aln-right {
    justify-content: flex-end;
  }

  .row.aln-top {
    align-items: flex-start;
  }

  .row.aln-middle {
    align-items: center;
  }

  .row.aln-bottom {
    align-items: flex-end;
  }

  .row > .imp-large {
    order: -1;
  }

  .row > .col-1-large {
    width: 8.33333%;
  }

  .row > .off-1-large {
    margin-left: 8.33333%;
  }

  .row > .col-2-large {
    width: 16.6667%;
  }

  .row > .off-2-large {
    margin-left: 16.6667%;
  }

  .row > .col-3-large {
    width: 25%;
  }

  .row > .off-3-large {
    margin-left: 25%;
  }

  .row > .col-4-large {
    width: 33.3333%;
  }

  .row > .off-4-large {
    margin-left: 33.3333%;
  }

  .row > .col-5-large {
    width: 41.6667%;
  }

  .row > .off-5-large {
    margin-left: 41.6667%;
  }

  .row > .col-6-large {
    width: 50%;
  }

  .row > .off-6-large {
    margin-left: 50%;
  }

  .row > .col-7-large {
    width: 58.3333%;
  }

  .row > .off-7-large {
    margin-left: 58.3333%;
  }

  .row > .col-8-large {
    width: 66.6667%;
  }

  .row > .off-8-large {
    margin-left: 66.6667%;
  }

  .row > .col-9-large {
    width: 75%;
  }

  .row > .off-9-large {
    margin-left: 75%;
  }

  .row > .col-10-large {
    width: 83.3333%;
  }

  .row > .off-10-large {
    margin-left: 83.3333%;
  }

  .row > .col-11-large {
    width: 91.6667%;
  }

  .row > .off-11-large {
    margin-left: 91.6667%;
  }

  .row > .col-12-large {
    width: 100%;
  }

  .row > .off-12-large {
    margin-left: 100%;
  }

  .row.gtr-0 {
    margin-top: 0;
    margin-left: 0;
  }

  .row.gtr-0 > * {
    padding: 0;
  }

  .row.gtr-0.gtr-uniform {
    margin-top: 0;
  }

  .row.gtr-0.gtr-uniform > * {
    padding-top: 0;
  }

  .row.gtr-25 {
    margin-top: -6.25px;
    margin-left: -6.25px;
  }

  .row.gtr-25 > * {
    padding: 6.25px 0 0 6.25px;
  }

  .row.gtr-25.gtr-uniform {
    margin-top: -6.25px;
  }

  .row.gtr-25.gtr-uniform > * {
    padding-top: 6.25px;
  }

  .row.gtr-50 {
    margin-top: -12.5px;
    margin-left: -12.5px;
  }

  .row.gtr-50 > * {
    padding: 12.5px 0 0 12.5px;
  }

  .row.gtr-50.gtr-uniform {
    margin-top: -12.5px;
  }

  .row.gtr-50.gtr-uniform > * {
    padding-top: 12.5px;
  }

  .row {
    margin-top: -25px;
    margin-left: -25px;
  }

  .row > * {
    padding: 25px 0 0 25px;
  }

  .row.gtr-uniform {
    margin-top: -25px;
  }

  .row.gtr-uniform > * {
    padding-top: 25px;
  }

  .row.gtr-150 {
    margin-top: -37.5px;
    margin-left: -37.5px;
  }

  .row.gtr-150 > * {
    padding: 37.5px 0 0 37.5px;
  }

  .row.gtr-150.gtr-uniform {
    margin-top: -37.5px;
  }

  .row.gtr-150.gtr-uniform > * {
    padding-top: 37.5px;
  }

  .row.gtr-200 {
    margin-top: -50px;
    margin-left: -50px;
  }

  .row.gtr-200 > * {
    padding: 50px 0 0 50px;
  }

  .row.gtr-200.gtr-uniform {
    margin-top: -50px;
  }

  .row.gtr-200.gtr-uniform > * {
    padding-top: 50px;
  }
}

@media screen and (width <= 980px) {
  .row {
    box-sizing: border-box;
    flex-wrap: wrap;
    align-items: stretch;
    display: flex;
  }

  .row > * {
    box-sizing: border-box;
  }

  .row.gtr-uniform > * > :last-child {
    margin-bottom: 0;
  }

  .row.aln-left {
    justify-content: flex-start;
  }

  .row.aln-center {
    justify-content: center;
  }

  .row.aln-right {
    justify-content: flex-end;
  }

  .row.aln-top {
    align-items: flex-start;
  }

  .row.aln-middle {
    align-items: center;
  }

  .row.aln-bottom {
    align-items: flex-end;
  }

  .row > .imp-medium {
    order: -1;
  }

  .row > .col-1-medium {
    width: 8.33333%;
  }

  .row > .off-1-medium {
    margin-left: 8.33333%;
  }

  .row > .col-2-medium {
    width: 16.6667%;
  }

  .row > .off-2-medium {
    margin-left: 16.6667%;
  }

  .row > .col-3-medium {
    width: 25%;
  }

  .row > .off-3-medium {
    margin-left: 25%;
  }

  .row > .col-4-medium {
    width: 33.3333%;
  }

  .row > .off-4-medium {
    margin-left: 33.3333%;
  }

  .row > .col-5-medium {
    width: 41.6667%;
  }

  .row > .off-5-medium {
    margin-left: 41.6667%;
  }

  .row > .col-6-medium {
    width: 50%;
  }

  .row > .off-6-medium {
    margin-left: 50%;
  }

  .row > .col-7-medium {
    width: 58.3333%;
  }

  .row > .off-7-medium {
    margin-left: 58.3333%;
  }

  .row > .col-8-medium {
    width: 66.6667%;
  }

  .row > .off-8-medium {
    margin-left: 66.6667%;
  }

  .row > .col-9-medium {
    width: 75%;
  }

  .row > .off-9-medium {
    margin-left: 75%;
  }

  .row > .col-10-medium {
    width: 83.3333%;
  }

  .row > .off-10-medium {
    margin-left: 83.3333%;
  }

  .row > .col-11-medium {
    width: 91.6667%;
  }

  .row > .off-11-medium {
    margin-left: 91.6667%;
  }

  .row > .col-12-medium {
    width: 100%;
  }

  .row > .off-12-medium {
    margin-left: 100%;
  }

  .row.gtr-0 {
    margin-top: 0;
    margin-left: 0;
  }

  .row.gtr-0 > * {
    padding: 0;
  }

  .row.gtr-0.gtr-uniform {
    margin-top: 0;
  }

  .row.gtr-0.gtr-uniform > * {
    padding-top: 0;
  }

  .row.gtr-25 {
    margin-top: -6.25px;
    margin-left: -6.25px;
  }

  .row.gtr-25 > * {
    padding: 6.25px 0 0 6.25px;
  }

  .row.gtr-25.gtr-uniform {
    margin-top: -6.25px;
  }

  .row.gtr-25.gtr-uniform > * {
    padding-top: 6.25px;
  }

  .row.gtr-50 {
    margin-top: -12.5px;
    margin-left: -12.5px;
  }

  .row.gtr-50 > * {
    padding: 12.5px 0 0 12.5px;
  }

  .row.gtr-50.gtr-uniform {
    margin-top: -12.5px;
  }

  .row.gtr-50.gtr-uniform > * {
    padding-top: 12.5px;
  }

  .row {
    margin-top: -25px;
    margin-left: -25px;
  }

  .row > * {
    padding: 25px 0 0 25px;
  }

  .row.gtr-uniform {
    margin-top: -25px;
  }

  .row.gtr-uniform > * {
    padding-top: 25px;
  }

  .row.gtr-150 {
    margin-top: -37.5px;
    margin-left: -37.5px;
  }

  .row.gtr-150 > * {
    padding: 37.5px 0 0 37.5px;
  }

  .row.gtr-150.gtr-uniform {
    margin-top: -37.5px;
  }

  .row.gtr-150.gtr-uniform > * {
    padding-top: 37.5px;
  }

  .row.gtr-200 {
    margin-top: -50px;
    margin-left: -50px;
  }

  .row.gtr-200 > * {
    padding: 50px 0 0 50px;
  }

  .row.gtr-200.gtr-uniform {
    margin-top: -50px;
  }

  .row.gtr-200.gtr-uniform > * {
    padding-top: 50px;
  }
}

@media screen and (width <= 736px) {
  .row {
    box-sizing: border-box;
    flex-wrap: wrap;
    align-items: stretch;
    display: flex;
  }

  .row > * {
    box-sizing: border-box;
  }

  .row.gtr-uniform > * > :last-child {
    margin-bottom: 0;
  }

  .row.aln-left {
    justify-content: flex-start;
  }

  .row.aln-center {
    justify-content: center;
  }

  .row.aln-right {
    justify-content: flex-end;
  }

  .row.aln-top {
    align-items: flex-start;
  }

  .row.aln-middle {
    align-items: center;
  }

  .row.aln-bottom {
    align-items: flex-end;
  }

  .row > .imp-small {
    order: -1;
  }

  .row > .col-1-small {
    width: 8.33333%;
  }

  .row > .off-1-small {
    margin-left: 8.33333%;
  }

  .row > .col-2-small {
    width: 16.6667%;
  }

  .row > .off-2-small {
    margin-left: 16.6667%;
  }

  .row > .col-3-small {
    width: 25%;
  }

  .row > .off-3-small {
    margin-left: 25%;
  }

  .row > .col-4-small {
    width: 33.3333%;
  }

  .row > .off-4-small {
    margin-left: 33.3333%;
  }

  .row > .col-5-small {
    width: 41.6667%;
  }

  .row > .off-5-small {
    margin-left: 41.6667%;
  }

  .row > .col-6-small {
    width: 50%;
  }

  .row > .off-6-small {
    margin-left: 50%;
  }

  .row > .col-7-small {
    width: 58.3333%;
  }

  .row > .off-7-small {
    margin-left: 58.3333%;
  }

  .row > .col-8-small {
    width: 66.6667%;
  }

  .row > .off-8-small {
    margin-left: 66.6667%;
  }

  .row > .col-9-small {
    width: 75%;
  }

  .row > .off-9-small {
    margin-left: 75%;
  }

  .row > .col-10-small {
    width: 83.3333%;
  }

  .row > .off-10-small {
    margin-left: 83.3333%;
  }

  .row > .col-11-small {
    width: 91.6667%;
  }

  .row > .off-11-small {
    margin-left: 91.6667%;
  }

  .row > .col-12-small {
    width: 100%;
  }

  .row > .off-12-small {
    margin-left: 100%;
  }

  .row.gtr-0 {
    margin-top: 0;
    margin-left: 0;
  }

  .row.gtr-0 > * {
    padding: 0;
  }

  .row.gtr-0.gtr-uniform {
    margin-top: 0;
  }

  .row.gtr-0.gtr-uniform > * {
    padding-top: 0;
  }

  .row.gtr-25 {
    margin-top: -3.75px;
    margin-left: -3.75px;
  }

  .row.gtr-25 > * {
    padding: 3.75px 0 0 3.75px;
  }

  .row.gtr-25.gtr-uniform {
    margin-top: -3.75px;
  }

  .row.gtr-25.gtr-uniform > * {
    padding-top: 3.75px;
  }

  .row.gtr-50 {
    margin-top: -7.5px;
    margin-left: -7.5px;
  }

  .row.gtr-50 > * {
    padding: 7.5px 0 0 7.5px;
  }

  .row.gtr-50.gtr-uniform {
    margin-top: -7.5px;
  }

  .row.gtr-50.gtr-uniform > * {
    padding-top: 7.5px;
  }

  .row {
    margin-top: -15px;
    margin-left: -15px;
  }

  .row > * {
    padding: 15px 0 0 15px;
  }

  .row.gtr-uniform {
    margin-top: -15px;
  }

  .row.gtr-uniform > * {
    padding-top: 15px;
  }

  .row.gtr-150 {
    margin-top: -22.5px;
    margin-left: -22.5px;
  }

  .row.gtr-150 > * {
    padding: 22.5px 0 0 22.5px;
  }

  .row.gtr-150.gtr-uniform {
    margin-top: -22.5px;
  }

  .row.gtr-150.gtr-uniform > * {
    padding-top: 22.5px;
  }

  .row.gtr-200 {
    margin-top: -30px;
    margin-left: -30px;
  }

  .row.gtr-200 > * {
    padding: 30px 0 0 30px;
  }

  .row.gtr-200.gtr-uniform {
    margin-top: -30px;
  }

  .row.gtr-200.gtr-uniform > * {
    padding-top: 30px;
  }
}

form label {
  color: #3e3e3e;
  margin: 0 0 .5em;
  font-weight: 700;
  display: block;
}

form input[type="text"], form input[type="email"], form input[type="password"], form select, form textarea {
  -webkit-appearance: none;
  color: #bbb;
  background: #fff;
  border: 1px solid #ddd;
  border-radius: 8px;
  width: 100%;
  padding: .75em;
  font-size: 1em;
  transition: background .2s ease-in-out, box-shadow .2s ease-in-out;
  display: block;
  box-shadow: inset 0 2px 3px 1px #0000000d, 0 1px #ffffff06;
}

form input[type="text"]:focus, form input[type="email"]:focus, form input[type="password"]:focus, form select:focus, form textarea:focus {
  background: #fafafa;
  box-shadow: inset 0 2px 5px #0000000d, 0 1px #ffffff06, inset 0 0 2px 1px #43bff0;
}

form textarea {
  height: 15em;
}

form .actions:last-child {
  margin-bottom: 0;
}

form ::-webkit-input-placeholder {
  color: #555 !important;
}

form :-moz-placeholder {
  color: #555 !important;
}

form ::-moz-placeholder {
  color: #555 !important;
}

form :-ms-input-placeholder {
  color: #555 !important;
}

form ::-moz-focus-inner {
  border: 0;
}

table, table.default {
  width: 100%;
}

table.default tr {
  border-top: 1px solid #eee;
}

table.default tr:first-child {
  border-top: 0;
}

table.default td {
  padding: .5em 1em;
}

table.default th {
  text-align: left;
  margin: 0 0 1em;
  padding: .5em 1em;
  font-weight: 600;
}

table.default thead {
  color: #fff;
  background: #4f4f4f;
}

section, article {
  margin-bottom: 3em;
}

section > :last-child, article > :last-child, section:last-child, article:last-child {
  margin-bottom: 0;
}

.image {
  display: inline-block;
  position: relative;
}

.image img, .image.fit {
  width: 100%;
  display: block;
}

.image.featured {
  width: 100%;
  margin: 0 0 2em;
  display: block;
}

.image.left {
  float: left;
  margin: 0 2em 2em 0;
}

.image.centered {
  margin: 0 0 2em;
  display: block;
}

.image.centered img {
  width: auto;
  margin: 0 auto;
}

input[type="button"], input[type="submit"], input[type="reset"], button, .button {
  -webkit-appearance: none;
  cursor: pointer;
  text-shadow: -1px -1px .5px #00000080;
  background-color: #43b3e0;
  border: 0;
  border-radius: 8px;
  outline: 0;
  max-width: 24em;
  padding: 1em 2.35em;
  font-size: 1.1em;
  font-weight: 700;
  text-decoration: none;
  transition: background-color .2s ease-in-out;
  display: inline-block;
  position: relative;
  overflow: hidden;
  box-shadow: inset 0 0 0 1px #00000080, inset 0 2px 1px #ffffffbf;
  color: #fff !important;
}

input[type="button"]:hover, input[type="submit"]:hover, input[type="reset"]:hover, button:hover, .button:hover {
  background-color: #43bff0;
  color: #fff !important;
}

input[type="button"]:active, input[type="submit"]:active, input[type="reset"]:active, button:active, .button:active {
  background-color: #3ba8d3;
}

input[type="button"].large, input[type="submit"].large, input[type="reset"].large, button.large, .button.large {
  letter-spacing: -.025em;
  font-size: 1.5em;
}

input[type="button"].alt, input[type="submit"].alt, input[type="reset"].alt, button.alt, .button.alt {
  background-color: #444;
  box-shadow: inset 0 0 0 1px #242424, inset 0 2px 1px #fff3;
}

input[type="button"].alt:hover, input[type="submit"].alt:hover, input[type="reset"].alt:hover, button.alt:hover, .button.alt:hover {
  background-color: #4f4f4f;
  color: #fff !important;
}

input[type="button"].alt:active, input[type="submit"].alt:active, input[type="reset"].alt:active, button.alt:active, .button.alt:active {
  background-color: #3f3f3f;
}

ul {
  padding-left: 1em;
  list-style: disc;
}

ul li {
  padding-left: .5em;
}

ol {
  padding-left: 1.25em;
  list-style: decimal;
}

ol li {
  padding-left: .25em;
}

ul.social {
  cursor: default;
  margin: 0;
  padding-left: 0;
  list-style: none;
}

ul.social li {
  margin: .25em;
  padding-left: 0;
  display: inline-block;
  position: relative;
  top: 0;
}

ul.social li a {
  border-radius: 6px;
  width: 48px;
  height: 48px;
  transition: top .2s ease-in-out;
  display: block;
  position: relative;
  top: 0;
}

ul.social li a:before {
  text-align: center;
  text-shadow: 1px 1px #ffffff1a;
  background-color: #444;
  border-radius: 6px;
  outline: 0;
  width: 48px;
  height: 48px;
  font-size: 26px;
  line-height: 48px;
  transition: background-color .2s ease-in-out;
  display: block;
  overflow: hidden;
  box-shadow: inset 0 0 0 1px #282828, inset 0 2px 1px #ffffff1a;
  color: #2e2e2e !important;
}

ul.social li a.fa-twitter {
  background-color: #2daae4;
}

ul.social li a.fa-facebook-f {
  background-color: #3c5a98;
}

ul.social li a.fa-dribbble {
  background-color: #c4376b;
}

ul.social li a.fa-linkedin-in {
  background-color: #006599;
}

ul.social li a.fa-tumblr {
  background-color: #51718a;
}

ul.social li a.fa-google-plus {
  background-color: #da2713;
}

ul.social li a.fa-github {
  background-color: #fafafa;
}

ul.social li a.fa-rss {
  background-color: #f2600b;
}

ul.social li a.fa-instagram {
  background-color: #e0d7c8;
}

ul.social li a.fa-foursquare {
  background-color: #39a3d4;
}

ul.social li a.fa-skype {
  background-color: #10bef1;
}

ul.social li a.fa-soundcloud {
  background-color: #fe5419;
}

ul.social li a.fa-youtube {
  background-color: #bf2e25;
}

ul.social li a.fa-blogger {
  background-color: #ff6501;
}

ul.social li a.fa-flickr {
  background-color: #0062db;
}

ul.social li a.fa-vimeo {
  background-color: #4c8ab0;
}

ul.social li a:hover {
  top: -5px;
}

ul.social li a:hover:before {
  background-color: #0000;
}

ul.actions {
  padding-left: 0;
  list-style: none;
}

ul.actions li {
  margin: 0 0 0 1em;
  padding-left: 0;
  display: inline-block;
}

ul.actions li:first-child {
  margin-left: 0;
}

.box {
  text-align: center;
  background: #fff;
  padding: 2em;
  box-shadow: inset 0 0 0 1px #00000026, 0 2px 3px #0000001a;
}

.box.style1 {
  padding: 3em 2em 3.5em;
}

.box.style1 h3 {
  margin-bottom: .5em;
}

.box.style2 h3 {
  margin-bottom: .25em;
}

.box.style2 .image {
  width: auto;
  margin: -4em 0 4em -4em;
  position: relative;
  top: 2em;
  left: 2em;
}

.wrapper {
  text-align: center;
  padding: 8em 0;
  box-shadow: inset 0 1px #0000000d, inset 0 2px 3px #0000001a;
}

.wrapper.style1 {
  background-color: #fff;
  background-image: none;
}

.wrapper.style2 {
  text-shadow: 1px 1px #fff;
  background-color: #fafafa;
}

.wrapper.style3 {
  text-shadow: 1px 1px #fff;
  background-color: #f4f4f4;
}

.wrapper.style4 {
  color: #999;
  text-shadow: -1px -1px #181818;
  background-color: #303030;
}

.wrapper.style4 h1, .wrapper.style4 h2, .wrapper.style4 h3, .wrapper.style4 h4, .wrapper.style4 h5, .wrapper.style4 h6 {
  color: #fff;
}

.wrapper.style4 form input[type="text"], .wrapper.style4 form input[type="password"], .wrapper.style4 form select, .wrapper.style4 form textarea {
  background: #282828;
  border: none;
}

.wrapper.style4 form input[type="text"]:focus, .wrapper.style4 form input[type="password"]:focus, .wrapper.style4 form select:focus, .wrapper.style4 form textarea:focus {
  background: #252525;
}

#nav {
  text-align: center;
  z-index: 10000;
  cursor: default;
  background-color: #282828;
  width: 100%;
  height: 3.5em;
  line-height: 3.5em;
  position: fixed;
  top: 0;
  left: 0;
}

#nav ul {
  margin-bottom: 0;
  padding-left: 0;
  list-style: none;
}

#nav li {
  padding-left: 0;
  display: inline-block;
}

#nav a {
  color: #fff;
  border-radius: 8px;
  outline: 0;
  height: 2.5em;
  padding: 0 1.25em;
  font-weight: 600;
  line-height: 2.5em;
  text-decoration: none;
  transition: background-color .2s ease-in-out;
  display: block;
  position: relative;
}

#nav a:hover {
  background: #383838;
  color: #fff !important;
}

#nav a.active {
  background: #484848;
}

#nav a.active:before {
  content: "";
  border-top: .6em solid #282828;
  border-left: .75em solid #0000;
  border-right: .75em solid #0000;
  margin-left: -.75em;
  display: block;
  position: absolute;
  bottom: -.6em;
  left: 50%;
}

body > article {
  margin-bottom: 0;
}

#top {
  text-align: left;
  padding: 10em 0;
}

#top .image {
  border-radius: 100%;
  width: 20em;
  height: 20em;
  margin: 0;
}

#top .image img {
  border-radius: 100%;
}

#top h1 {
  margin-top: .35em;
}

#top p {
  font-size: 1.5em;
  line-height: 1.75em;
}

#top p a {
  color: inherit;
}

#contact footer {
  font-size: .9em;
}

#copyright {
  color: #666;
  margin-bottom: 0;
  padding-left: 0;
  font-size: 1em;
  line-height: 1em;
  list-style: none;
}

#copyright li {
  border-left: 1px solid #00000080;
  margin: 0 0 0 1em;
  padding: 0 0 0 1em;
  display: inline-block;
  box-shadow: -1px 0 #ffffff1a;
}

#copyright li:first-child {
  box-shadow: none;
  border: 0;
  margin-left: 0;
  padding-left: 0;
}

#copyright a {
  color: inherit;
  transition: color .2s ease-in-out;
}

#copyright a:hover {
  color: #777;
}

@media screen and (width <= 1280px) {
  body, input, textarea, select {
    font-size: 11pt;
  }

  header {
    margin: 0 0 4em;
  }

  .wrapper {
    text-align: center;
    padding: 5em 0;
  }

  .wrapper.style4 .row-special {
    margin: 2em 0 0;
    padding: 2em 0;
  }

  #top {
    padding: 8em 0;
  }

  #top .image {
    width: 24em;
    height: 24em;
    margin: 0;
  }
}

@media screen and (width <= 980px) {
  #top {
    text-align: center;
    padding: 5em 0;
  }

  #top .image {
    margin: 0 auto 2em;
  }
}

@media screen and (width <= 736px) {
  body {
    padding-top: 44px;
  }

  body, input, textarea, select {
    letter-spacing: 0;
    font-size: 10pt;
    line-height: 1.75em;
  }

  h1, h2, h3, h4, h5, h6 {
    margin: 0 0 .4em;
    font-size: 1.25em;
  }

  h1 {
    font-size: 2.25em;
    line-height: 1.25em;
  }

  header {
    margin: 0 0 2em;
  }

  header > p {
    font-size: 1.25em;
  }

  footer {
    margin: 2.5em 0 0;
  }

  footer > p {
    font-size: 1.25em;
  }

  hr {
    margin: 1.5em 0 2em;
  }

  section, article {
    clear: both;
  }

  input[type="button"], input[type="submit"], input[type="reset"], button, .button {
    text-align: center;
    width: 100%;
    padding: 1em 0;
    font-size: 1.2em;
  }

  input[type="button"].large, input[type="submit"].large, input[type="reset"].large, button.large, .button.large {
    letter-spacing: 0;
    font-size: 1.2em;
  }

  ul.social {
    padding: 1em .5em 0;
  }

  ul.social li {
    margin: .5em;
  }

  ul.social li a {
    top: 0 !important;
  }

  ul.social li a:before {
    background-color: #0000 !important;
  }

  ul.actions {
    margin: 0;
  }

  ul.actions li {
    margin: 15px 0 0;
    display: block;
  }

  ul.actions li:first-child {
    margin-top: 0;
  }

  .box {
    padding: 30px 20px;
    margin: 0 0 20px !important;
  }

  .box h3 {
    margin-bottom: .25em;
  }

  .box .image.centered {
    margin-bottom: 1em;
  }

  .box .image.featured {
    width: auto;
    margin: -50px 0 50px -40px;
    position: relative;
    top: 20px;
    left: 20px;
  }

  .box.style1, .box.style2 {
    max-width: 32em;
    margin-left: auto !important;
    margin-right: auto !important;
  }

  .wrapper {
    text-align: center;
    padding: 3em 0;
  }

  #nav {
    height: 44px;
    line-height: 44px;
  }

  #nav a {
    height: inherit;
    line-height: inherit;
    border-radius: 0;
    padding: 0 .75em;
  }

  #nav a:hover {
    background-color: #0000;
  }

  #top {
    padding: 3em 0;
  }

  #top .image {
    width: 15em;
    height: 15em;
    margin-bottom: 0;
  }

  #top p {
    font-size: 1em;
  }

  #contact footer {
    margin: 0;
  }

  #copyright {
    margin: 0;
    font-size: 1em;
  }

  #copyright li {
    box-shadow: none;
    border-left: 0;
    margin: 1em 0 0;
    padding: 0;
    display: block;
  }

  #copyright li:first-child {
    margin-top: 0;
  }
}
/*# sourceMappingURL=index.b9cb61b2.css.map */
